import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { find as _find, get as _get, values as _values } from 'lodash-es';

import { fetchResourceDetail } from 'actions';

import View from './view.js';

export const TotalPaymentsContainerTypes = {
  USER: 'user',
  PROPERTY: 'property'
};

class TotalPaymentsContainer extends Component {
  componentWillMount() {
    const { fetchPropertyTotals, type } = this.props;

    if (type === TotalPaymentsContainerTypes.PROPERTY) {
      fetchPropertyTotals();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { userId, fetchUserTotals } = this.props;

    if (nextProps.userId && userId !== nextProps.userId && nextProps.type === TotalPaymentsContainerTypes.USER) {
      fetchUserTotals(nextProps.userId);
    }
  }

  render() {
    return <View {...this.props} />;
  }
}

TotalPaymentsContainer.propTypes = {
  type: PropTypes.oneOf(_values(TotalPaymentsContainerTypes))
};

const mapStateToProps = (state, ownProps) => {
  const { type } = ownProps;
  const slug = ownProps.match.params.slug;
  const userId = _get(state, 'auth.me.id');
  const resource = type === TotalPaymentsContainerTypes.USER ? _get(state, `resources.users[${userId}]`) : _find(state.resources.properties, property => property.attributes.slug === slug);

  return {
    paid: _get(resource, 'attributes.paid_total'),
    unpaid: _get(resource, 'attributes.unpaid_total'),
    userId
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const slug = ownProps.match.params.slug;
  return {
    fetchPropertyTotals: () =>
      dispatch(
        fetchResourceDetail('properties', slug, {
          action: 'totals',
          mergeProperties: true
        })
      ),
    fetchUserTotals: userId =>
      dispatch(
        fetchResourceDetail('users', userId, {
          action: 'totals',
          mergeProperties: true,
          skipCache: true
        })
      )
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TotalPaymentsContainer)
);
