import React from 'react';
import Flexbox from 'flexbox-react';

import ResetPasswordForm from 'components/shared/ResetPasswordForm';

import './styles.less';

const Welcome = () => {
  return (
    <Flexbox flexDirection="column" width="600px">
      <h2>Hello, and Welcome to Hello</h2>
      <Flexbox flexDirection="column" marginTop="20px">
        <div>
          Hello is the place to view and manage your sponsorships. We’re just getting started, so expect things to change over time. <a href="mailto:hello@standard.tv">We also take requests</a>.
        </div>
      </Flexbox>

      <Flexbox marginTop="20px">Ready to get started? Enter your email below and we’ll send you a link to create your password.</Flexbox>

      <Flexbox marginTop="25px" flexDirection="column">
        <ResetPasswordForm />
      </Flexbox>
    </Flexbox>
  );
};

export default Welcome;
