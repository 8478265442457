import 'url-search-params-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { Provider } from 'react-redux';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

import Auth from './auth';
import { setAuthToken } from './actions';
import reducer from './reducers/index';
import './index.css';
import Util from 'utilities';

import AppContainer from './components/App';

// Util.initGapi();

const appleTouchIconSizes = [57, 60, 72, 76, 114, 120, 144, 152, 180, 192];
appleTouchIconSizes.map(size => {
  Util.createAndAppendIconLink('apple-touch-icon', size);
  return size;
});

const iconSizes = [16, 32, 96, 192];
iconSizes.map(size => {
  Util.createAndAppendIconLink('icon', size);
  return size;
});

function promiseMiddleware(next) {
  return action => {
    return action && typeof action.then === 'function' ? action.then(next) : next(action);
  };
}

const store = createStore(reducer, applyMiddleware(createLogger()), applyMiddleware(thunkMiddleware), applyMiddleware(promiseMiddleware));

// Set the auth token
const token = Auth.getLoginCookie();
if (token !== undefined) {
  store.dispatch(setAuthToken(token));
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <AppContainer />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// registerServiceWorker();

unregister();
