import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { fetchResourceList } from 'actions';
import Util from 'utilities';

import View from './view.js';

class NavigationContainer extends Component {
  render() {
    const { location, ...other } = this.props;

    return <View defaultSelectedKey={location.pathname} {...other} />;
  }
}

const mapStateToProps = state => {
  return {
    isCreator: Util.isCreator(state),
    isLoggedIn: Util.isLoggedIn(state),
    isSponsor: Util.isSponsor(state),
    isStaff: Util.isStaff(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchProperties: () => dispatch(fetchResourceList('properties', { pageSize: 1000 }))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationContainer));
