import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';

import { Avatar, Dropdown, Menu } from 'antd';

import './styles.css';

const UserNameAndIcon = ({ image, importFromThanks, isLoggedIn, isStaff, name, logout, token }) => {
  if (!isLoggedIn) {
    return <span />;
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <div onClick={logout}>Log Out</div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Flexbox flexDirection="row" alignItems="center" justifyContent="flex-end" className="UserNameAndIcon">
      <Avatar icon="user" className="UserNameAndIcon__icon" src={image} />{' '}
      <Dropdown overlay={menu}>
        <div className="ant-dropdown-link">{name}</div>
      </Dropdown>
    </Flexbox>
  );
};

UserNameAndIcon.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string
};

UserNameAndIcon.defaultProps = {
  name: 'Me',
  image: null
};

export default UserNameAndIcon;
