import React from 'react';
import Flexbox from 'flexbox-react';
import { Link } from 'react-router-dom';
import { Alert, Breadcrumb, LocaleProvider, Layout } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import classnames from 'classnames';
import { get as _get } from 'lodash-es';

import routes from 'routes';

import NavigationContainer from 'components/shared/Navigation';
import PrivateRoute from 'components/shared/PrivateRoute';
import TotalPaymentsContainer, { TotalPaymentsContainerTypes } from 'components/shared/TotalPayments';
import UserNameAndIconContainer from 'components/shared/UserNameAndIcon';

const { Header, Content, Sider } = Layout;

export default ({ alert, breadcrumbItems, children, fullWidth }) => {
  let alertBanner;
  if (_get(alert, 'message') !== undefined) {
    alertBanner = (
      <div className="App__alert">
        <Alert banner={true} type={alert.type} closable={true} message={alert.message} />
      </div>
    );
  }

  return (
    <LocaleProvider locale={enUS}>
      <Layout
        className={classnames('App__layout', {
          'App__layout--fullWidth': fullWidth
        })}
      >
        {alertBanner}
        <Header className="App__header">
          <Layout>
            <Sider breakpoint="xs" collapsedWidth="0">
              <UserNameAndIconContainer />
            </Sider>
            <Content className="App__content">
              <Flexbox flexDirection="row" justifyContent="space-between">
                <Breadcrumb separator="›">
                  {breadcrumbItems.map(({ path, label }) => (
                    <Breadcrumb.Item key={path}>
                      <Link to={path}>{label}</Link>
                    </Breadcrumb.Item>
                  ))}
                </Breadcrumb>

                <PrivateRoute exact path={routes.Schedule.Detail.path} render={() => <TotalPaymentsContainer type={TotalPaymentsContainerTypes.PROPERTY} />} />
                <PrivateRoute exact path={routes.Schedule.Index.path} render={() => <TotalPaymentsContainer type={TotalPaymentsContainerTypes.USER} />} />
              </Flexbox>
            </Content>
          </Layout>
        </Header>
        <Layout>
          <Sider breakpoint="xs" collapsedWidth="0">
            <NavigationContainer />
          </Sider>
          <Content className="App__content">{children}</Content>
        </Layout>
      </Layout>
    </LocaleProvider>
  );
};
