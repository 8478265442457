import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { requestPasswordReset } from 'actions';

import View from './view.js';

class ResetPasswordFormContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { email: '' };

    this.onEmailChangeBind = this.onEmailChange.bind(this);
    this.onSubmitBind = this.onSubmit.bind(this);
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onSubmit() {
    const { requestPasswordReset } = this.props;

    requestPasswordReset(this.state.email);
  }

  render() {
    return <View email={this.state.email} notice={this.state.notice} onEmailChange={this.onEmailChangeBind} onSubmit={this.onSubmitBind} />;
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  requestPasswordReset: email => dispatch(requestPasswordReset(email))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordFormContainer));
