import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { get as _get } from 'lodash-es';

import Api from 'api';
import Util from 'utilities';
import { logout } from 'actions';

import View from './view.js';

const mapStateToProps = state => ({
  image: _get(state, 'auth.me.attributes.image_url'),
  isLoggedIn: Util.isLoggedIn(state),
  isStaff: Util.isStaff(state),
  name: _get(state, 'auth.me.attributes.name'),
  token: _get(state, 'auth.token')
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  importFromThanks: token => {
    Api.get('internal/import_from_thanks/', token);
  },
  logout: () => dispatch(logout(ownProps.history))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(View)
);
