/* eslint-disable */
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import './styles.css';

import routes from 'routes';

import Layout from 'components/shared/Layout';
import LayoutSimple from 'components/shared/LayoutSimple';
import PrivateRoute from 'components/shared/PrivateRoute';
import PublicRoute from 'components/shared/PublicRoute';

const Analytics = Loadable({
  loader: () => import('components/route/Analytics'),
  loading: () => null
});
const Authorize = Loadable({
  loader: () => import('components/route/Authorize'),
  loading: () => null
});
const Financials = Loadable({
  loader: () => import('components/route/Financials'),
  loading: () => null
});
const Creators = Loadable({
  loader: () => import('components/route/Creators'),
  loading: () => null
});
const Creator = Loadable({
  loader: () => import('components/route/Creator'),
  loading: () => null
});
const DebitAdd = Loadable({
  loader: () => import('components/route/DebitAdd'),
  loading: () => null
});
const Debits = Loadable({
  loader: () => import('components/route/Debits'),
  loading: () => null
});
const EpisodeDetail = Loadable({
  loader: () => import('components/route/Episode'),
  loading: () => null
});
const InsertionOrder = Loadable({
  loader: () => import('components/route/InsertionOrder'),
  loading: () => null
});
const InsertionOrderEdit = Loadable({
  loader: () => import('components/route/InsertionOrderEdit'),
  loading: () => null
});
const InsertionOrders = Loadable({
  loader: () => import('components/route/InsertionOrders'),
  loading: () => null
});
const Invoice = Loadable({
  loader: () => import('components/route/Invoice'),
  loading: () => null
});
const Invoices = Loadable({
  loader: () => import('components/route/Invoices'),
  loading: () => null
});
const Inventory = Loadable({
  loader: () => import('components/route/Inventory'),
  loading: () => null
});
const Login = Loadable({
  loader: () => import('components/route/Login'),
  loading: () => null
});
const PropertyAdd = Loadable({
  loader: () => import('components/route/PropertyAdd'),
  loading: () => null
});
const PropertyEdit = Loadable({
  loader: () => import('components/route/PropertyEdit'),
  loading: () => null
});
const ResetPasswordComplete = Loadable({
  loader: () => import('components/route/ResetPasswordComplete'),
  loading: () => null
});
const ResetPasswordRequest = Loadable({
  loader: () => import('components/route/ResetPasswordRequest'),
  loading: () => null
});
const Resources = Loadable({
  loader: () => import('components/route/Resources'),
  loading: () => null
});
const Properties = Loadable({
  loader: () => import('components/route/Properties'),
  loading: () => null
});
const Schedule = Loadable({
  loader: () => import('components/route/Schedule'),
  loading: () => null
});
const SponsorAdd = Loadable({
  loader: () => import('components/route/SponsorAdd'),
  loading: () => null
});
const SponsorAnalytics = Loadable({
  loader: () => import('components/route/SponsorAnalytics'),
  loading: () => null
});
const SponsorDetail = Loadable({
  loader: () => import('components/route/Sponsor'),
  loading: () => null
});
const Sponsors = Loadable({
  loader: () => import('components/route/Sponsors'),
  loading: () => null
});
const Sponsorship = Loadable({
  loader: () => import('components/route/Sponsorship'),
  loading: () => null
});
const Sponsorships = Loadable({
  loader: () => import('components/route/Sponsorships'),
  loading: () => null
});
const Statements = Loadable({
  loader: () => import('components/route/Statements'),
  loading: () => null
});
const StatementDetail = Loadable({
  loader: () => import('components/route/Statement'),
  loading: () => null
});

import Welcome from 'components/route/Welcome';
import NotFound from 'components/route/NotFound';

class App extends Component {
  componentWillReceiveProps(nextProps) {
    if (this.props.location !== nextProps.location) {
      window.scrollTo(0, 0);
    }
  }

  singlePropertyScheduleDetailPath() {
    return `${routes.Schedule.Index.path}/${this.props.propertiesArray[0].attributes.slug}`;
  }

  render() {
    const { propertiesArray, isSponsor, isStaff, userIdentified, isLoggedIn } = this.props;

    return (
      <Switch>
        <PrivateRoute path={routes.Analytics.Index.path} render={() => <Layout>{isSponsor ? <SponsorAnalytics /> : <Analytics />}</Layout>} />
        <PrivateRoute
          path={routes.Financials.Index.path}
          render={() => (
            <Layout>
              <Financials />
            </Layout>
          )}
        />
        <PrivateRoute
          path={routes.InsertionOrder.Index.path}
          render={() => (
            <Layout>
              <InsertionOrders />
            </Layout>
          )}
        />
        <Route
          path={routes.Login.Index.path}
          render={() => (
            <Layout>
              <Login />
            </Layout>
          )}
        />
        <PrivateRoute
          exact
          path={routes.Authorize.Service.path}
          render={props => (
            <Layout>
              <Authorize {...props} />
            </Layout>
          )}
        />
        <PrivateRoute
          exact
          path={routes.Creators.Index.path}
          render={() => (
            <Layout>
              <Creators />
            </Layout>
          )}
        />
        <PrivateRoute
          exact
          path={routes.Creators.Detail.path}
          render={() => (
            <Layout>
              <Creator />
            </Layout>
          )}
        />
        <PrivateRoute
          exact
          path={routes.Debits.Index.path}
          render={() => (
            <Layout>
              <Debits />
            </Layout>
          )}
        />
        <PrivateRoute
          exact
          path={routes.Debits.AddDebit.path}
          render={() => (
            <Layout>
              <DebitAdd />
            </Layout>
          )}
        />
        <PrivateRoute
          exact
          path={routes.Properties.AddProperty.path}
          render={() => (
            <Layout>
              <PropertyAdd />
            </Layout>
          )}
        />
        <PrivateRoute
          exact
          path={routes.Properties.EditProperty.path}
          render={() => (
            <Layout>
              <PropertyEdit />
            </Layout>
          )}
        />
        <PrivateRoute
          path={routes.Resources.Index.path}
          render={() => (
            <Layout>
              <Resources />
            </Layout>
          )}
        />
        <PrivateRoute
          exact
          path={routes.Schedule.Index.path}
          render={props =>
            !isStaff && propertiesArray.length === 1 ? (
              <Redirect to={this.singlePropertyScheduleDetailPath()} />
            ) : (
              <Layout>
                <Properties {...props} />
              </Layout>
            )
          }
        />
        <PrivateRoute
          exact
          path={routes.Sales.Index.path}
          render={() => (
            <Layout>
              <InsertionOrders />
            </Layout>
          )}
        />
        <PrivateRoute
          path={routes.Sales.InsertionOrder.path}
          render={() => (
            <Layout>
              <InsertionOrderEdit />
            </Layout>
          )}
        />
        <PrivateRoute
          exact
          path={routes.Schedule.Detail.path}
          render={() => (
            <Layout>
              <Schedule />
            </Layout>
          )}
        />
        <PrivateRoute
          path={routes.Schedule.Episode.path}
          render={() => (
            <Layout>
              <EpisodeDetail />
            </Layout>
          )}
        />
        <Route
          exact
          path={routes.PasswordReset.Index.path}
          render={() => (
            <Layout>
              <ResetPasswordRequest />
            </Layout>
          )}
        />
        <Route
          path={routes.PasswordReset.Done.path}
          render={() => (
            <Layout>
              <ResetPasswordComplete />
            </Layout>
          )}
        />
        <Route
          exact
          path="/"
          render={() => {
            const redirectToSchedule = propertiesArray.length === 1 ? <Redirect to={this.singlePropertyScheduleDetailPath()} /> : <Redirect to={routes.Schedule.Index.path} />;

            if (isLoggedIn && userIdentified) {
              return isSponsor ? <Redirect to={routes.Sponsorships.Index.path} /> : redirectToSchedule;
            } else if (isLoggedIn) {
              return <div />;
            } else {
              return redirectToSchedule;
            }
          }}
        />
        <PrivateRoute
          path={routes.Inventory.Index.path}
          render={() => (
            <Layout fullWidth>
              <Inventory />
            </Layout>
          )}
        />
        <PrivateRoute
          path={routes.Invoices.Index.path}
          exact
          render={() => (
            <Layout>
              <Invoices />
            </Layout>
          )}
        />
        <PrivateRoute
          path={routes.Invoices.Detail.path}
          render={() => {
            return isStaff ? (
              <Layout>
                <Invoice />
              </Layout>
            ) : (
              <LayoutSimple>
                <Invoice />
              </LayoutSimple>
            );
          }}
        />
        <PrivateRoute
          exact
          path={routes.Sponsors.Index.path}
          render={() => (
            <Layout>
              <Sponsors />
            </Layout>
          )}
        />
        <PrivateRoute
          exact
          path={routes.Sponsors.AddSponsor.path}
          render={() => (
            <Layout>
              <SponsorAdd />
            </Layout>
          )}
        />
        <PrivateRoute
          exact
          path={routes.Sponsors.Detail.path}
          render={() => {
            return !isStaff 
              ? <Redirect to={routes.Sponsors.Index.path} /> 
              : (
                <Layout>
                  <SponsorDetail />
                </Layout>
              );
          }}
        />
        <PrivateRoute
          exact
          path={routes.Sponsors.Sponsorships.path}
          render={() => (
            <Layout>
              <Sponsorships />
            </Layout>
          )}
        />
        <PrivateRoute
          exact
          path={routes.Sponsors.SponsorshipDetail.path}
          render={() => (
            <Layout>
              <Sponsorship />
            </Layout>
          )}
        />
        <PrivateRoute
          exact
          path={routes.Sponsorships.Index.path}
          render={() => (
            <Layout>
              <Sponsorships />
            </Layout>
          )}
        />
        <PrivateRoute
          exact
          path={routes.Statements.Index.path}
          render={() => (
            <Layout>
              <Statements />
            </Layout>
          )}
        />
        <PrivateRoute
          exact
          path={routes.Statements.Detail.path}
          render={() => (
            <Layout>
              <StatementDetail />
            </Layout>
          )}
        />
        <PublicRoute
          path={routes.Welcome.Index.path}
          render={() => (
            <Layout>
              <Welcome />
            </Layout>
          )}
        />
        <PrivateRoute
          exact
          path={routes.InsertionOrder.Detail.path}
          render={() => (
            <LayoutSimple>
              <InsertionOrder />
            </LayoutSimple>
          )}
        />
        <Route
          render={() => (
            <Layout>
              <NotFound />
            </Layout>
          )}
        />
      </Switch>
    );
  }
}

export default App;
