import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';

import Util from 'utilities';

import './styles.css';

import TextSmall from 'components/shared/TextSmall';

const TotalPayments = ({ paid, unpaid }) => {
  if (paid || unpaid) {
    return (
      <Flexbox flexDirection="column" alignContent="flex-end" className="TotalPayments">
        <TextSmall>Lifetime: {paid === null ? '$0' : Util.renderCurrency(paid)}</TextSmall>
        <TextSmall>Upcoming: {unpaid === null ? '$0' : Util.renderCurrency(unpaid)}</TextSmall>
      </Flexbox>
    );
  } else {
    return false;
  }
};

TotalPayments.propTypes = {
  paid: PropTypes.number,
  unpaid: PropTypes.number
};

export default TotalPayments;
