import React from 'react';
import classnames from 'classnames';
import { get as _get } from 'lodash-es';

import { Alert, Layout, LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';

import './styles.css';

const { Content } = Layout;

const LayoutSimple = ({ alert, className, children }) => {
  let alertBanner;
  if (_get(alert, 'message') !== undefined) {
    alertBanner = (
      <div className="App__alert">
        <Alert banner={true} type={alert.type} closable={true} message={alert.message} />
      </div>
    );
  }

  return (
    <LocaleProvider locale={enUS}>
      <Layout className={classnames('LayoutSimple', className)}>
        {alertBanner}
        <Content>{children}</Content>
      </Layout>
    </LocaleProvider>
  );
};

export default LayoutSimple;
