// @flow

import { ALERT_CLEAR, ALERT_FAILURE, ALERT_SUCCESS } from 'actions';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case ALERT_CLEAR:
      return {};
    case ALERT_FAILURE:
      return Object.assign({}, state, {
        message: action.message,
        type: 'error'
      });
    case ALERT_SUCCESS:
      return Object.assign({}, state, {
        message: action.message,
        type: 'success'
      });
    default:
      return state;
  }
};

export default reducer;
