import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import View from './view.js';

class LayoutContainer extends Component {
  render() {
    return <View {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    alert: state.alert,
    breadcrumbItems: state.breadcrumb.items
  };
};

const mapDispatchToProps = dispatch => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutContainer));
