import React from 'react';
import routes from 'routes';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';

const Navigation = ({ defaultSelectedKey, isCreator, isSponsor, isLoggedIn, isStaff, scheduleRoute }) => {
  let schedule;
  if (isLoggedIn && (isCreator || isStaff)) {
    schedule = (
      <Menu.Item key={routes.Schedule.Index.path}>
        <Link to={routes.Schedule.Index.path}>{routes.Schedule.Index.title}</Link>
      </Menu.Item>
    );
  }

  let users;
  if (isStaff) {
    users = (
      <Menu.Item key={routes.Creators.Index.path}>
        <Link to={routes.Creators.Index.path}>{routes.Creators.Index.title}</Link>
      </Menu.Item>
    );
  }

  let sponsors;
  if (isStaff || isCreator) {
    sponsors = (
      <Menu.Item key={routes.Sponsors.Index.path}>
        <Link to={routes.Sponsors.Index.path}>{routes.Sponsors.Index.title}</Link>
      </Menu.Item>
    );
  }

  let financials;
  if (isStaff) {
    financials = (
      <Menu.Item key={routes.Financials.Index.path}>
        <Link to={routes.Financials.Index.path}>{routes.Financials.Index.title}</Link>
      </Menu.Item>
    );
  }

  let statements;
  if (isLoggedIn && (isStaff || isCreator)) {
    statements = (
      <Menu.Item key={routes.Statements.Index.path}>
        <Link to={routes.Statements.Index.path}>{routes.Statements.Index.title}</Link>
      </Menu.Item>
    );
  }

  let sales;
  if (isStaff) {
    sales = (
      <Menu.Item key={routes.Sales.Index.path}>
        <Link to={routes.Sales.Index.path}>{routes.Sales.Index.title}</Link>
      </Menu.Item>
    );
  }

  let sponsorships;
  if (isSponsor) {
    sponsorships = (
      <Menu.Item key={routes.Sponsorships.Index.path}>
        <Link to={routes.Sponsorships.Index.path}>{routes.Sponsorships.Index.title}</Link>
      </Menu.Item>
    );
  }

  let invoices;
  if (isStaff || isSponsor) {
    invoices = (
      <Menu.Item key={routes.Invoices.Index.path}>
        <Link to={isStaff ? routes.Invoices.Index.path + '?status=unpaid' : routes.Invoices.Index.path}>{routes.Invoices.Index.title}</Link>
      </Menu.Item>
    );
  }

  let ios;
  if (isSponsor) {
    ios = (
      <Menu.Item key={routes.InsertionOrder.Index.path}>
        <Link to={routes.InsertionOrder.Index.path}>{routes.InsertionOrder.Index.title}</Link>
      </Menu.Item>
    );
  }

  let inventory;
  if (isStaff || isSponsor) {
    inventory = (
      <Menu.Item key={routes.Inventory.Index.path}>
        <Link to={routes.Inventory.Index.path}>{routes.Inventory.Index.title}</Link>
      </Menu.Item>
    );
  }

  let analytics;
  if (isLoggedIn && (isCreator || isStaff || isSponsor)) {
    analytics = (
      <Menu.Item key={routes.Analytics.Index.path}>
        <Link to={routes.Analytics.Index.path}>{routes.Analytics.Index.title}</Link>
      </Menu.Item>
    );
  }

  let resources;
  if (isLoggedIn && (isCreator || isStaff)) {
    resources = (
      <Menu.Item key={routes.Resources.Index.path}>
        <Link to={routes.Resources.Index.path}>{routes.Resources.Index.title}</Link>
      </Menu.Item>
    );
  }

  return (
    <Menu mode="vertical" defaultSelectedKeys={[defaultSelectedKey]}>
      {schedule}
      {users}
      {sponsors}
      {financials}
      {statements}
      {sales}
      {sponsorships}
      {ios}
      {invoices}
      {inventory}
      {analytics}
      {resources}
    </Menu>
  );
};

Navigation.propTypes = {};

export default Navigation;
