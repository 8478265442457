// @flow
import React, { Component } from 'react';
import { withRouter, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash-es';

class PublicRoute extends Component {
  render() {
    const { component: TheComponent, token, render, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props => {
          if (!token) {
            return render ? render(props) : <TheComponent {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/'
                }}
              />
            );
          }
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  token: _get(state, 'auth.token')
});

const mapDispatchToProps = dispatch => ({});

PublicRoute.contextTypes = {
  component: PropTypes.object
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PublicRoute)
);
