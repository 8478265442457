import React from 'react';
import Flexbox from 'flexbox-react';

import { Link } from 'react-router-dom';

import './styles.css';

const NotFound = props => {
  return (
    <div>
      <h1>
        <span aria-label="thinking_face" role="img" className="NotFound__emoji">
          🤔
        </span>
      </h1>
      <Flexbox marginBottom="20px">Whoops! Couldn't the page you were looking for.</Flexbox>
      <Link to="/">Get me out of here</Link>
    </div>
  );
};

export default NotFound;
