// @flow
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import Util from 'utilities';

export const config = {};
switch (window.location.hostname) {
  case 'localhost':
    config.API_HOST = 'http://localhost:3001';
    break;
  case 'staging.hello.standard.tv':
  case 'staging.hello.standard.tv.s3-website-us-east-1.amazonaws.com':
    config.API_HOST = 'https://api-staging.standard.tv';
    break;
  default:
    config.API_HOST = 'https://api.standard.tv';
}

class Api {
  constructor() {
    this.client = axios.create({
      baseURL: config.API_HOST
    });
    this.cache = setupCache({
      maxAge: 15 * 60 * 1000,
      debug: true,
      exclude: {
        query: false
      },
      key: request => `${request.url}?${Util.url.serialize(request.params)}`
    });
  }

  headers(token) {
    let headers = {
      'Content-Type': 'application/vnd.api+json'
    };

    if (token) {
      headers.Authorization = `Token ${token}`;
    }

    return headers;
  }

  get(url, token, params, args, skipCache = false) {
    return this.client(
      Object.assign(
        {},
        {
          headers: this.headers(token),
          method: 'GET',
          url,
          params
        },
        {
          adapter: skipCache ? null : this.cache.adapter,
          ...args
        }
      )
    );
  }

  post(url, token, params, data) {
    return this.client({
      headers: this.headers(token),
      method: 'post',
      url,
      params,
      data: { data }
    });
  }

  patch(url, token, data) {
    return this.client({
      headers: this.headers(token),
      method: 'PATCH',
      url,
      data: { data }
    });
  }

  delete(url, token) {
    return this.client({
      headers: this.headers(token),
      method: 'DELETE',
      url
    });
  }

  postForm(url, token, form) {
    var params = new URLSearchParams();
    Object.keys(form).forEach(function(key) {
      params.append(key, form[key]);
    });

    const headers = Object.assign({}, this.headers(token), {
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.client({
      headers: headers,
      method: 'post',
      url,
      data: params
    });
  }

  postFile(url, token, file) {
    const formData = new FormData();
    formData.append('file', file);

    return this.client({
      headers: { ...this.headers(token), 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url,
      data: formData
    });
  }
}

export default new Api();
