import React from 'react';
import { Button, Input } from 'antd';
import Flexbox from 'flexbox-react';

import './styles.css';

const ResetPasswordForm = ({ email, onEmailChange, onSubmit }) => {
  return (
    <Flexbox className="ResetPasswordRequest" flexDirection="column">
      <label htmlFor="email">Email address</label>
      <Input name="email" value={email} onChange={onEmailChange} onPressEnter={onSubmit} />
      <Flexbox justifyContent="space-between">
        <Button type="primary" onClick={onSubmit}>
          Submit
        </Button>
      </Flexbox>
    </Flexbox>
  );
};
export default ResetPasswordForm;
