// @flow
import moment from 'moment';
import { each as _each, get as _get, groupBy as _groupBy, isArray as _isArray, pick as _pick, reduce as _reduce, snakeCase as _snakeCase, split as _split } from 'lodash-es';

import _λcompact from 'lodash/fp/compact';
import _λflow from 'lodash/fp/flow';
import _λfromPairs from 'lodash/fp/fromPairs';
import _λmap from 'lodash/fp/map';
import _λreplace from 'lodash/fp/replace';
import _λsplit from 'lodash/fp/split';

import { config as apiConfig } from 'api';

import getSumOfUnsoldSponsorshipsForEvents from './getSumOfUnsoldSponsorshipsForEvents';
import getSumOfEventsForPropertiesInMonth from './getSumOfEventsForPropertiesInMonth';

const url = {
  query: param => {
    return _λflow(
      _λreplace('?', ''), // a=b454&c=dhjjh&f=g6hksdfjlksd
      _λsplit('&'), // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
      _λmap(keyValString => _split(keyValString, '=', 2)), // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
      _λfromPairs // {"a":"b454","c":"dhjjh","f":"g6hksdfjlksd"}
    )(window.location.search)[param];
  },
  serialize: obj => {
    const str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }
};

const basicSorter = key => (a, b) => {
  if (a[key] < b[key]) return 1;
  if (a[key] > b[key]) return -1;
  return 0;
};

const file = {
  basename: (str, sep = '/') => str.substr(str.lastIndexOf(sep) + 1)
};

const s3ProxyUrl = (bucketName, filename) => `${apiConfig.API_HOST}/s3/${bucketName}/${filename}`;

const sorter = {
  date: key => (a, b) => moment(_get(a, key)).diff(moment(_get(b, key))),
  number: key => (a, b) => _get(a, key, 0) - _get(b, key, 0),
  string: key => (a, b) => {
    const aName = _get(a, key, '').toLowerCase();
    const bName = _get(b, key, '').toLowerCase();
    if (aName > bName) return 1;
    if (aName < bName) return -1;
    return 0;
  }
};

// JSON API resource utilities

const attachIncludesToResource = (resource, state) => {
  const resourceWithAttachedIncludes = Object.assign({}, resource);

  if (_get(resource, 'relationships')) {
    _each(resource.relationships, (value, key) => {
      if (!_isArray(value.data) && _get(value, 'data.id')) {
        const type = pluralizeKey(_snakeCase(_get(value, 'data.type')));
        const include = _get(state, `resources.${type}[${value.data.id}]`);
        resourceWithAttachedIncludes[key] = attachIncludesToResource(include, state);
      } else if (_isArray(value.data)) {
        const ids = _λflow(_λmap(reference => _get(reference, 'id')), _λcompact)(value.data);
        const type = pluralizeKey(_snakeCase(_get(value, 'data[0].type')));
        const includes = _pick(_get(state, `resources.${type}`, {}), ids);

        resourceWithAttachedIncludes[key] = includes;
      }
    });
  }

  return resourceWithAttachedIncludes;
};

const resourceArrayToObject = resources => {
  return _reduce(
    resources,
    (memo, resource) => {
      memo[resource.id] = resource;
      return memo;
    },
    {}
  );
};

const storeIncludes = (state, resources) => {
  const resourcesByType = _groupBy(resources, resource => pluralizeKey(_snakeCase(resource.type)));
  const newState = Object.assign({}, state);

  _each(resourcesByType, (value, key) => {
    newState[key] = Object.assign(_get(newState, key, {}), resourceArrayToObject(value));
  });

  return newState;
};

const pluralizeKey = key => {
  if (key.match(/s$/)) {
    return key;
  } else if (key.match(/y$/)) {
    return `${key.substring(0, key.length - 1)}ies`;
  } else {
    return `${key}s`;
  }
};

const renderCurrency = (value, forceCents) => {
  if (typeof value === 'undefined' || value === null) return '';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    useGrouping: true,
    currency: 'USD',
    minimumFractionDigits: forceCents ? 2 : 0,
    maximumFractionDigits: forceCents ? 2 : 0
  }).format(value);
};

const renderCurrencyForInput = value => {
  const floatValue = parseFloat(value);
  const prefix = floatValue < 0 ? '-$' : '$';
  const valueAsString = value.toString();
  const hasAPeriod = valueAsString.indexOf('.') !== -1;
  const endsWithAPeriod = valueAsString[valueAsString.length - 1] === '.';
  const endsWithOneDecimalPlace = valueAsString[valueAsString.length - 2] === '.';
  if (endsWithAPeriod || endsWithOneDecimalPlace) return prefix + value;
  if (hasAPeriod) return renderCurrency(value, true);
  return value;
};

const isLoggedIn = state => _get(state, 'auth.token') !== undefined;

const isStaff = state => _get(state, 'auth.me.auth_user.attributes.is_staff') === true;

const isCreator = state => _get(state, 'auth.me.type') === 'User';

const isSponsor = state => _get(state, 'auth.me.type') === 'SponsorUser';

const hashColorFromString = (str = '') => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let c = (hash & 0x00ffffff).toString(16).toUpperCase();

  return '#' + ('00000'.substring(0, 6 - c.length) + c);
};

// Environment utilities
const isProduction = () => {
  return window.location.hostname === 'hello.standard.tv';
};

const viewportSize = () => ({
  width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
  height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
});

const viewportMobile = () => viewportSize().width <= 540;

const getCopyVarsFromSponsorhip = sponsorshipWithSponsor => {
  let copyPerShowVarsJson = _get(sponsorshipWithSponsor, `sponsor.attributes.copy_per_show_vars`);
  if (copyPerShowVarsJson === undefined || copyPerShowVarsJson === '') {
    copyPerShowVarsJson = '{}';
  }

  const copyPerShowVars = JSON.parse(copyPerShowVarsJson);
  const vars = _get(copyPerShowVars, _get(sponsorshipWithSponsor, 'event.property.id'), {});

  vars.url = _get(sponsorshipWithSponsor, 'attributes.custom_link_url') || vars.url || _get(sponsorshipWithSponsor, 'sponsor.attributes.copy_link_url') || '';

  vars.code = _get(sponsorshipWithSponsor, 'attributes.custom_code') || vars.code || '';

  return vars;
};

const getIconForUrl = url => {
  return 'https://plus.google.com/_/favicon?domain_url=' + url;
};

const gapi = window.gapi;
const isDevEnv = process.env.NODE_ENV === 'development' ? true : false;
const loadClientWhenGapiReady = script => {
  if (isDevEnv) console.log('Trying To Load Client!');
  if (isDevEnv) console.log(script);
  if (script.getAttribute('gapi_processed')) {
    if (isDevEnv) console.log('Client is ready! Now you can access gapi. :)');
    if (window.location.hostname === 'localhost') {
      gapi.client.load('http://localhost:8080/_ah/api/discovery/v1/apis/metafields/v1/rest').then(
        response => {
          if (isDevEnv) console.log('Connected to metafields API locally.');
        },
        function(err) {
          if (isDevEnv) console.log('Error connecting to metafields API locally.');
        }
      );
    }
  } else {
    if (isDevEnv) console.log("Client wasn't ready, trying again in 100ms");
    setTimeout(() => {
      loadClientWhenGapiReady(script);
    }, 100);
  }
};

const initGapi = () => {
  if (isDevEnv) console.log('Initializing GAPI...');
  if (isDevEnv) console.log('Creating the google script tag...');

  const script = document.createElement('script');
  script.onload = () => {
    if (isDevEnv) console.log('Loaded script, now loading our api...');
    // Gapi isn't available immediately so we have to wait until it is to use gapi.
    loadClientWhenGapiReady(script);
  };
  script.src = 'https://apis.google.com/js/api.js';

  document.body.appendChild(script);
};

const createAndAppendIconLink = (type, size) => {
  const folder = type === 'icon' ? 'transparent' : 'square';
  const link = document.createElement('link');
  link.rel = type;
  link.sizes = `${size}x${size}`;
  link.href = `${process.env.PUBLIC_URL}/icons/${process.env.NODE_ENV}/${folder}/${size}x${size}.png`;
  if (type === 'icon') link.type = 'image.png';
  document.head.appendChild(link);
};

const checkIsSponsorshipADraft = sponsorship => {
  const insertionOrder = _get(sponsorship, 'insertion_order.attributes', null);
  if (insertionOrder) {
    const insertionOrderDate = insertionOrder.created_at;
    const draftedAfterDec12018 = moment(new Date(insertionOrderDate)).isAfter(new Date('2018-12-01'));
    const wasSentToSponsor = !!_get(sponsorship, 'insertion_order.attributes.sent_to_sponsor_at', false);
    const isSigned = !!_get(sponsorship, 'insertion_order.attributes.signed_at', false);
    return !!draftedAfterDec12018 && !wasSentToSponsor && !isSigned;
  } else {
    return false;
  }
};

export const redirect = (event, history, url) => {
  if (event.metaKey === true) {
    window.open(url, '_blank');
  } else {
    history.push(url);
  }
};

export default {
  attachIncludesToResource,
  basicSorter,
  checkIsSponsorshipADraft,
  createAndAppendIconLink,
  file,
  getCopyVarsFromSponsorhip,
  getIconForUrl,
  hashColorFromString,
  initGapi,
  isCreator,
  isLoggedIn,
  isProduction,
  isSponsor,
  isStaff,
  loadClientWhenGapiReady,
  pluralizeKey,
  renderCurrency,
  renderCurrencyForInput,
  resourceArrayToObject,
  s3ProxyUrl,
  sorter,
  storeIncludes,
  url,
  viewportMobile,
  viewportSize,
  getSumOfUnsoldSponsorshipsForEvents,
  getSumOfEventsForPropertiesInMonth
};
