import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get as _get, isEmpty as _isEmpty, values as _values } from 'lodash-es';

import { fetchCurrentUser, fetchResourceList, logout } from 'actions';
import Util from 'utilities';
import Analytics from 'analytics';

import View from './view.js';

class AppContainer extends Component {
  componentWillMount() {
    Analytics.init();

    // Log each "pageview"
    Analytics.logEvent(this.context.router.route.location.pathname);
    this.context.router.history.listen((location, action) => {
      Analytics.logEvent(location.pathname);
    });

    const { fetchCurrentUser, fetchProperties, isCreator, isLoggedIn, logout } = this.props;

    if (isLoggedIn) {
      fetchCurrentUser().then(response => {
        const status = _get(response, 'response.status', 200);
        if (status === 401) {
          logout();
        }
      });
      if (isCreator) {
        fetchProperties();
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isLoggedIn === false && nextProps.isLoggedIn === true) {
      this.props.fetchCurrentUser();
    }
  }

  render() {
    return <View {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    alert: state.alert,
    breadcrumbItems: state.breadcrumb.items,
    isCreator: Util.isCreator(state),
    isLoggedIn: Util.isLoggedIn(state),
    propertiesArray: state.resources.properties ? _values(state.resources.properties) : [],
    isSponsor: Util.isSponsor(state),
    isStaff: Util.isStaff(state),
    userIdentified: !_isEmpty(_get(state, 'auth.me'))
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  logout: () => dispatch(logout()),
  fetchProperties: () => dispatch(fetchResourceList('properties', { pageSize: 1000 }))
});

AppContainer.contextTypes = {
  router: PropTypes.object
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppContainer)
);
