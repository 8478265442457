// @flow

import { combineReducers } from 'redux';

import alert from './alert';
import auth from './auth';
import breadcrumb from './breadcrumb';
import resources from './resources';
import ui from './ui';

export default combineReducers({
  alert,
  auth,
  breadcrumb,
  resources,
  ui
});
